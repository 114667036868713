<template>
    <div class="wrapper flex flex-column">
        <Header :navList="navList" navCurrentId="2" :logo="logoUrl"></Header>
        <div class="flex-item">
            <div v-if="subjectList.length" class="container filter-wrapper">
                <filter-tab title="学科" :list="subjectList" :isShowMore="true" :selected="subjectIndex" @clickItem="handleFilterEvent('subjectIndex', $event)"></filter-tab>
            </div>
            <!-- 筛选条件 -->
            <div v-if="subjectIndex != -1" class="filter-condition mt20 active">
                <div class="container flex y-center x-left">
                    <div class="search-text"
                        v-if="subjectIndex != -1">搜索条件：</div>
                    <a-breadcrumb class="search-breadcrumb">
                        <a-breadcrumb-item v-if="subjectIndex != -1">{{ subjectList[subjectIndex].operate_name }}
                        </a-breadcrumb-item>
                    </a-breadcrumb>
                    <a-button class="clear-btn" @click="handleClearEvent"
                        v-if="subjectIndex != -1"
                        style="margin-left:20px;" size="small">清除</a-button>
                </div>
            </div>
            <div class="live-content container flex mt20">
                <div class="live-list flex-item">
                    <a-spin :spinning="loading" tip="Loading...">
                        <div class="mh">
                            <!-- 视频推荐模块 -->
                            <div class="flex mb40 live-banner" v-if="recommendList.length">
                                <a class="recommend-first" @click="videoJumpDetail(bigVideoId)">
                                    <img style="object-fit:cover;" class="rf-image" :src="bigImg | urlFilter(360)" alt="直播标题">
                                </a>
                                <div class="recommend-list">
                                    <common-title title="推荐回放" :isMore="false"></common-title>
                                    <a v-for="(item, index) in recommendList" :key="index"
                                        class="recommend-item t-l" @click="videoJumpDetail(item.id)">
                                        <div :title="item.title" class="fs16 color333 row-1">{{ item.title }}</div>
                                        <div class="fs14 color999">
                                            <i class="med"></i>{{ item.author }}
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div v-if="chargeVideoList.length" class="video-list">
                                <common-title title="收费视频" path="/course/index"></common-title>
                                <course-item width="230px" height="130px" :data-list="chargeVideoList"
                                    @clickEvent="handleChargeVideoLink($event)" :target-type="1"></course-item>
                                <a-empty v-if="!chargeVideoList.length && !loading" />
                            </div>

                            <div class="video-list">
                                <common-title title="会议回放" path="/meeting-video-list"></common-title>
                                <course-item width="230px" height="130px" :data-list="meetingVideoList"
                                    @clickEvent="handleMeetingLink($event)" :target-type="1"></course-item>
                                <a-empty v-if="!meetingVideoList.length && !loading" />
                            </div>

                            <div class="video-list" v-for="(item, index) in videoList" :key="index">
                                <common-title class="mt20" :title="item.title"
                                    :path="'/video-list?category_id=' + item.id"></common-title>
                                <template v-if="item.list">
                                    <course-item width="230px" height="130px" :data-list="item.list"></course-item>
                                    <a-empty v-if="!item.list.length && !loading" />
                                </template>
                            </div>
                        </div>
                    </a-spin>
                </div>
                <div class="live-right">
                    <!-- 申请入驻讲师 -->
                    <!-- <img class="apply-teacher" src="~@/assets/images/apply-teacher.png" alt=""> -->
                    <!--  class="mt50" -->
                    <div>
                        <layout-right :config="layoutRightConfig"></layout-right>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import commonTitle from '@/components/common-title';
import Header from '@/components/layout/layout-header';
import layoutRight from '@/components/layout/layout-right';
import Footer from '@/components/layout/layout-footer';
import filterTab from '@/components/filter-tab';
import textItem from '@/components/text-item';
import acrossMediaItem from '@/components/across-media-item.vue';
import videoPlaybackItem from '@/components/video-playback-item.vue';
import courseItem from '@/components/courser-list-item';
import { videoLogo, mediaNavList } from '@/config/const'
import { jsonFormat } from '@/utils/jsonFormat';
import { videoJumpDetail } from '@/utils/jumpPageMethods';
import storage from 'store';
import { mapState } from 'vuex';
let layoutRightConfig = {
    news: {
        show: true, // 是否显示
        methods: 'post',
        title: '会议动态', // 标题
        moreLink:'/infomation-list?index=115',
        data: {
            page: 1,
            limit: 5,
            type: 115,
        },   // 参数条件
    },
    correlationMeet: { //相关会议
        show: true, // 是否显示
        title: '推荐会议', // 标题
        methods: 'get',
        data: {
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            page: 1,
            pageSize: 3,
            subject_ids: storage.get('subjectIds') || ''
        },   // 参数条件
    },
    correlationLive: { //相关直播
        show: true, // 是否显示
        methods: 'get',
        title: '推荐直播', // 标题
        data: {
            tj_token: storage.get('tid'),
            page: 1,
            limit: 3,
            subject_ids: storage.get('subjectIds') || ''
        },   // 参数条件
    },

}
export default {
    name: "VideoClass",
    components: {
        commonTitle,
        Header,
        layoutRight,
        filterTab,
        textItem,
        acrossMediaItem,
        videoPlaybackItem,
        courseItem,
        Footer
    },
    data() {
        return {
            logoUrl: videoLogo,
            navList: mediaNavList,//顶部菜单
            // 学科筛选
            subjectIndex: -1,
            subjectList: [],
            bigImg: '', //推荐视频大图
            bigVideoId: '',
            // 视频分类
            resourceList: {},
            // 视频分类列表数据
            videoList: [],
            // 收费视频
            chargeVideoList: [],
            // 视频推荐
            recommendList: [],
            // 会议回放视频
            meetingVideoList: [],
            // 右侧配置
            layoutRightConfig: {},
            loading: true,
        }
    },
    computed: {
        ...mapState(['userInfo'])
    },
    methods: {
        videoJumpDetail,
        getSubject() {
            return this.$store.dispatch('getSubjectList')
        },
        // 清除筛选
        handleClearEvent(){
            this.handleFilterEvent('subjectIndex', -1);
        },
        // 获取推荐视频
        getVideoLike(subject_id) {
            return this.request.get('GetRemmondVideoList',{
                page: 1,
                PageSize: 3,
                subject_id,
                sort: 4
            })
        },
        // 获取视频分类
        GetResourceCategoryList() {
            return this.request.get('GetResourceCategoryList')
        },
        // 收费视频回放
        handleChargeVideoLink(item){
            this.$router.push({
                path:'/course/play?id='+item.id
            })
        },
        // 会议专题回放
        handleMeetingLink(item) {
            this.$router.push({
                path: '/special-detail',
                query: {
                    type_id: item.type_id,
                    type: item.type,
                }
            })
        },
        // 获取收费视频
        getChargeVideoList(subject_ids){
            return this.request.get('getPremiumVideoList',{page:1,limit:3,subject_ids});
        },
        // 获取会议回放
        getMeetingPlayback(subject_id) {
            return this.request.get('GetOpenVideoMeetingLive', { limit: 3, page: 1, subject_id })
        },
        // 获取视频分类列表
        GetCategoryVideoList(item, data) {
            this.request.get('GetVideoListNew', data).then(res => {
                let list = res.data.list
                list.forEach(items => {
                    items.img = items.default_pic_url
                })
                item.list = list
                this.$forceUpdate()
            })
        },
        // 学科切换事件
        handleFilterEvent(key, e) {
            key && (this[key] = e);

            // 用户url学科或者已选学科
            let subject_ids = this.$route.query.subject_id;
            let operate_subject = subject_ids;

            // 切换后的学科
            if(this.subjectIndex != -1){
                subject_ids = this.subjectList[this.subjectIndex].subject_ids;
                operate_subject = this.subjectList[this.subjectIndex].id;
            }
            
            // 视频推荐
            this.getVideoLike(subject_ids).then(res=>{
                this.recommendList = res.data.list.slice(0,3) || []
                if(this.recommendList.length){
                    this.bigVideoId = this.recommendList[0].id
                    this.bigImg = this.recommendList[0].default_pic_url
                }
            })
            this.videoList.forEach(item => {
                let data = {
                    page: 1,
                    PageSize: 3,
                    no_count: false,
                    subject_ids,
                    class: item.category_id
                }
                this.GetCategoryVideoList(item, data)
            });

            // 会议回放
            this.getMeetingPlayback(subject_ids).then(res => {
                this.meetingVideoList = jsonFormat(res.data.list, { img: 'card_img', hits: 'video_view' });
            });

            // 收费视频
            this.getChargeVideoList(subject_ids).then(res => {
                this.chargeVideoList = jsonFormat(res.data.list, { img: 'default_pic_url' });
            })

            // 右侧数据修改
            layoutRightConfig.news.data.operate_id = operate_subject;
            layoutRightConfig.correlationMeet.data.subject_ids = subject_ids;
            layoutRightConfig.correlationLive.data.subject_ids = subject_ids;
            this.layoutRightConfig = JSON.parse(JSON.stringify(layoutRightConfig));

        },
        pageInit() {
            // url筛选学科
            let { subject_id } = this.$route.query;
            // 用户自己关注的学科
            subject_id = subject_id || this.userInfo.operate_subject;
            let requestArr = [
                this.getSubject(),
                this.GetResourceCategoryList(),
            ]
            Promise.all(requestArr).then(res => {

                // 学科列表
                let subjectList = [];
                res[0].data.forEach((item,index) => {
                    item.name = item.operate_name;
                    if(subject_id == item.id){
                        this.subjectIndex = index;
                    }
                    subjectList.push(item);
                })
                this.subjectList = subjectList;

                // 视频资源分类
                this.resourceList = res[1].data
                let videoList = []
                for (let k in this.resourceList) {
                    videoList.push({
                        id: k,
                        title: this.resourceList[k],
                        category_id: k
                    })
                }
                this.videoList = videoList;

                // 触发学科修改事件
                this.handleFilterEvent();

                this.loading = false
            })
        },
    },
    created() {
        this.pageInit();
    }
}
</script>

<style lang="less" scoped>
.wrapper {
    min-height: 100vh;
    background: url("~@/assets/images/live-nav-top-bg.png") repeat-x;
    background-size: 1920 auto;
}

.filter-wrapper {
    background: #ffffff;
    border-radius: 10px;
    padding: 5px 15px 5px 15px;
}

.filter-condition {
    background: #fff;
    overflow: hidden;
    height: 8px;
    &.active {
        height: 50px;
        line-height: 50px;
    }
    /deep/.ant-breadcrumb>span:last-child {
        color: inherit;
    }
}

.live-content {
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    box-sizing: border-box;
}

.live-list {
    margin-right: 30px;

    .mh {
        min-height: 500px;
    }
}

// 直播推荐
.recommend-first {
    width: 50%;

    .rf-image {
        width: 100%;
        height: 208px;
    }
}

.recommend-list {
    width: calc(50% - 20px);
    margin-left: 20px;

    .recommend-item {
        padding-bottom: 7px;
        margin-top: 7px;
        display: block;
        border-bottom: 1px dashed #d8d8d8;

        &:last-child {
            padding-bottom: 0;
            border: 0;
        }

        &:nth-of-type(1) {
            margin-top: 0;
        }
    }
}

.live-right {
    width: 400px;

    .paper-writing {
        height: 24px;
        display: flex;
        cursor: pointer;
    }

    .apply-teacher {
        width: 100%;
        height: 92px;
    }
}
@media screen and (max-width:768px){
    .wrapper{
         .filter-condition.active{
            margin-top:10px;
            height:40px;
            line-height:40px;
            .container{
                padding:0 10px;
                display: flex;
            }
        }
        .filter-wrapper{
            padding: 2px 0;
            border-radius: 0;
            position: sticky;
            top: 50px;
            background: #f2f4fa;
            z-index: 9;
        }
        .live-content{
            margin-top:0px;
            display: block;
            padding: 0;
            padding:0;
            .live-list{
                width: 100%;
                .live-banner{
                    display: block;
                    width: 100%;
                    margin-bottom: 20px;
                    .recommend-first{
                        width: 100%;
                        height: auto;
                        .rf-image{
                            height: auto;
                        }
                    }
                    .recommend-list{
                        width: 100%;
                        height: auto;
                        margin-top: 20px;
                        margin-left: 0;
                        padding: 0 10px;
                    }
                }
                .video-list{
                    padding: 0 10px;
                }
            }
            .live-right{
                width: 100%;
                margin-top: 30px;
                padding: 0 10px;
            }
        }
    }
}
</style>